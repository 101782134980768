.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}

.logo {
    cursor: pointer;
    color: white;
    font-size: 2rem;
}

.logo:hover {
    color: white;
    text-decoration: none;
}

.link {
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
}

.link.active {
    color:gray;
}

.link:hover {
    color: gray;
}

.navlink {
    color: white;
}

.navmenu {
    align-items: center;
    justify-content: flex-end;
}

.nav-container {
    max-width: 90%;
}

.toggle {
    color: white;
}